import { default as aboutbwd2FXEM5dMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/about.vue?macro=true";
import { default as login1YH1UTl2uCMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/auth/login.vue?macro=true";
import { default as faqzvlICayXp7Meta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/faq.vue?macro=true";
import { default as index8xf9hOZckhMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/index.vue?macro=true";
import { default as _1_46startDXTFsJWj2XMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/1.start.vue?macro=true";
import { default as _2_46sessionVwPsZnVtZzMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/2.session.vue?macro=true";
import { default as _3_46finishyURK5fnMPDMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/3.finish.vue?macro=true";
import { default as form5zHJmwsHiGMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form.vue?macro=true";
import { default as homeWtNUx4qycPMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/home.vue?macro=true";
import { default as onboardingXioqkCxwvhMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/onboarding.vue?macro=true";
import { default as prepare80A788L8StMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/prepare.vue?macro=true";
import { default as _1_46create_45teamsZ1K1czPIKGMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/1.create-teams.vue?macro=true";
import { default as _2_46teams1weavjdTtjMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/2.teams.vue?macro=true";
import { default as _3_46sessionsDpJcIrHgjMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/3.session.vue?macro=true";
import { default as _4_46resultsOw8OVs2XOkMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/4.results.vue?macro=true";
import { default as selectAdkNLLhRZeMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/select.vue?macro=true";
import { default as finishE1OTBrrmUCMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/finish.vue?macro=true";
import { default as homeMFmMl3SND5Meta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/home.vue?macro=true";
import { default as onboardinglNetkGYvrxMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/onboarding.vue?macro=true";
import { default as prepare1eIdgEdsujMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/prepare.vue?macro=true";
import { default as resumezZF9EFch53Meta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/resume.vue?macro=true";
import { default as scan2i2OvWtF5QMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/scan.vue?macro=true";
import { default as selectfG7LlznXifMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/select.vue?macro=true";
import { default as settingsZ17uU3QHWiMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/settings.vue?macro=true";
import { default as system5ek9qshbMlMeta } from "/home/forge/app-test.healthdevelopmentinstitute.com/pages/system.vue?macro=true";
export default [
  {
    name: aboutbwd2FXEM5dMeta?.name ?? "about",
    path: aboutbwd2FXEM5dMeta?.path ?? "/about",
    meta: aboutbwd2FXEM5dMeta || {},
    alias: aboutbwd2FXEM5dMeta?.alias || [],
    redirect: aboutbwd2FXEM5dMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/about.vue").then(m => m.default || m)
  },
  {
    name: login1YH1UTl2uCMeta?.name ?? "auth-login",
    path: login1YH1UTl2uCMeta?.path ?? "/auth/login",
    meta: login1YH1UTl2uCMeta || {},
    alias: login1YH1UTl2uCMeta?.alias || [],
    redirect: login1YH1UTl2uCMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: faqzvlICayXp7Meta?.name ?? "faq",
    path: faqzvlICayXp7Meta?.path ?? "/faq",
    meta: faqzvlICayXp7Meta || {},
    alias: faqzvlICayXp7Meta?.alias || [],
    redirect: faqzvlICayXp7Meta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: index8xf9hOZckhMeta?.name ?? "index",
    path: index8xf9hOZckhMeta?.path ?? "/",
    meta: index8xf9hOZckhMeta || {},
    alias: index8xf9hOZckhMeta?.alias || [],
    redirect: index8xf9hOZckhMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: form5zHJmwsHiGMeta?.name ?? "quiz-form",
    path: form5zHJmwsHiGMeta?.path ?? "/quiz/form",
    children: [
  {
    name: _1_46startDXTFsJWj2XMeta?.name ?? "quiz-form-1.start",
    path: _1_46startDXTFsJWj2XMeta?.path ?? "1.start",
    meta: _1_46startDXTFsJWj2XMeta || {},
    alias: _1_46startDXTFsJWj2XMeta?.alias || [],
    redirect: _1_46startDXTFsJWj2XMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/1.start.vue").then(m => m.default || m)
  },
  {
    name: _2_46sessionVwPsZnVtZzMeta?.name ?? "quiz-form-2.session",
    path: _2_46sessionVwPsZnVtZzMeta?.path ?? "2.session",
    meta: _2_46sessionVwPsZnVtZzMeta || {},
    alias: _2_46sessionVwPsZnVtZzMeta?.alias || [],
    redirect: _2_46sessionVwPsZnVtZzMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/2.session.vue").then(m => m.default || m)
  },
  {
    name: _3_46finishyURK5fnMPDMeta?.name ?? "quiz-form-3.finish",
    path: _3_46finishyURK5fnMPDMeta?.path ?? "3.finish",
    meta: _3_46finishyURK5fnMPDMeta || {},
    alias: _3_46finishyURK5fnMPDMeta?.alias || [],
    redirect: _3_46finishyURK5fnMPDMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form/3.finish.vue").then(m => m.default || m)
  }
],
    meta: form5zHJmwsHiGMeta || {},
    alias: form5zHJmwsHiGMeta?.alias || [],
    redirect: form5zHJmwsHiGMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/form.vue").then(m => m.default || m)
  },
  {
    name: homeWtNUx4qycPMeta?.name ?? "quiz-home",
    path: homeWtNUx4qycPMeta?.path ?? "/quiz/home",
    meta: homeWtNUx4qycPMeta || {},
    alias: homeWtNUx4qycPMeta?.alias || [],
    redirect: homeWtNUx4qycPMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/home.vue").then(m => m.default || m)
  },
  {
    name: onboardingXioqkCxwvhMeta?.name ?? "quiz-onboarding",
    path: onboardingXioqkCxwvhMeta?.path ?? "/quiz/onboarding",
    meta: onboardingXioqkCxwvhMeta || {},
    alias: onboardingXioqkCxwvhMeta?.alias || [],
    redirect: onboardingXioqkCxwvhMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/onboarding.vue").then(m => m.default || m)
  },
  {
    name: prepare80A788L8StMeta?.name ?? "quiz-prepare",
    path: prepare80A788L8StMeta?.path ?? "/quiz/prepare",
    meta: prepare80A788L8StMeta || {},
    alias: prepare80A788L8StMeta?.alias || [],
    redirect: prepare80A788L8StMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/prepare.vue").then(m => m.default || m)
  },
  {
    name: _1_46create_45teamsZ1K1czPIKGMeta?.name ?? "quiz-quiz-1.create-teams",
    path: _1_46create_45teamsZ1K1czPIKGMeta?.path ?? "/quiz/quiz/1.create-teams",
    meta: _1_46create_45teamsZ1K1czPIKGMeta || {},
    alias: _1_46create_45teamsZ1K1czPIKGMeta?.alias || [],
    redirect: _1_46create_45teamsZ1K1czPIKGMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/1.create-teams.vue").then(m => m.default || m)
  },
  {
    name: _2_46teams1weavjdTtjMeta?.name ?? "quiz-quiz-2.teams",
    path: _2_46teams1weavjdTtjMeta?.path ?? "/quiz/quiz/2.teams",
    meta: _2_46teams1weavjdTtjMeta || {},
    alias: _2_46teams1weavjdTtjMeta?.alias || [],
    redirect: _2_46teams1weavjdTtjMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/2.teams.vue").then(m => m.default || m)
  },
  {
    name: _3_46sessionsDpJcIrHgjMeta?.name ?? "quiz-quiz-3.session",
    path: _3_46sessionsDpJcIrHgjMeta?.path ?? "/quiz/quiz/3.session",
    meta: _3_46sessionsDpJcIrHgjMeta || {},
    alias: _3_46sessionsDpJcIrHgjMeta?.alias || [],
    redirect: _3_46sessionsDpJcIrHgjMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/3.session.vue").then(m => m.default || m)
  },
  {
    name: _4_46resultsOw8OVs2XOkMeta?.name ?? "quiz-quiz-4.results",
    path: _4_46resultsOw8OVs2XOkMeta?.path ?? "/quiz/quiz/4.results",
    meta: _4_46resultsOw8OVs2XOkMeta || {},
    alias: _4_46resultsOw8OVs2XOkMeta?.alias || [],
    redirect: _4_46resultsOw8OVs2XOkMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/quiz/4.results.vue").then(m => m.default || m)
  },
  {
    name: selectAdkNLLhRZeMeta?.name ?? "quiz-select",
    path: selectAdkNLLhRZeMeta?.path ?? "/quiz/select",
    meta: selectAdkNLLhRZeMeta || {},
    alias: selectAdkNLLhRZeMeta?.alias || [],
    redirect: selectAdkNLLhRZeMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/quiz/select.vue").then(m => m.default || m)
  },
  {
    name: finishE1OTBrrmUCMeta?.name ?? "scan-finish",
    path: finishE1OTBrrmUCMeta?.path ?? "/scan/finish",
    meta: finishE1OTBrrmUCMeta || {},
    alias: finishE1OTBrrmUCMeta?.alias || [],
    redirect: finishE1OTBrrmUCMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/finish.vue").then(m => m.default || m)
  },
  {
    name: homeMFmMl3SND5Meta?.name ?? "scan-home",
    path: homeMFmMl3SND5Meta?.path ?? "/scan/home",
    meta: homeMFmMl3SND5Meta || {},
    alias: homeMFmMl3SND5Meta?.alias || [],
    redirect: homeMFmMl3SND5Meta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/home.vue").then(m => m.default || m)
  },
  {
    name: onboardinglNetkGYvrxMeta?.name ?? "scan-onboarding",
    path: onboardinglNetkGYvrxMeta?.path ?? "/scan/onboarding",
    meta: onboardinglNetkGYvrxMeta || {},
    alias: onboardinglNetkGYvrxMeta?.alias || [],
    redirect: onboardinglNetkGYvrxMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/onboarding.vue").then(m => m.default || m)
  },
  {
    name: prepare1eIdgEdsujMeta?.name ?? "scan-prepare",
    path: prepare1eIdgEdsujMeta?.path ?? "/scan/prepare",
    meta: prepare1eIdgEdsujMeta || {},
    alias: prepare1eIdgEdsujMeta?.alias || [],
    redirect: prepare1eIdgEdsujMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/prepare.vue").then(m => m.default || m)
  },
  {
    name: resumezZF9EFch53Meta?.name ?? "scan-resume",
    path: resumezZF9EFch53Meta?.path ?? "/scan/resume",
    meta: resumezZF9EFch53Meta || {},
    alias: resumezZF9EFch53Meta?.alias || [],
    redirect: resumezZF9EFch53Meta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/resume.vue").then(m => m.default || m)
  },
  {
    name: scan2i2OvWtF5QMeta?.name ?? "scan-scan",
    path: scan2i2OvWtF5QMeta?.path ?? "/scan/scan",
    meta: scan2i2OvWtF5QMeta || {},
    alias: scan2i2OvWtF5QMeta?.alias || [],
    redirect: scan2i2OvWtF5QMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/scan.vue").then(m => m.default || m)
  },
  {
    name: selectfG7LlznXifMeta?.name ?? "scan-select",
    path: selectfG7LlznXifMeta?.path ?? "/scan/select",
    meta: selectfG7LlznXifMeta || {},
    alias: selectfG7LlznXifMeta?.alias || [],
    redirect: selectfG7LlznXifMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/scan/select.vue").then(m => m.default || m)
  },
  {
    name: settingsZ17uU3QHWiMeta?.name ?? "settings",
    path: settingsZ17uU3QHWiMeta?.path ?? "/settings",
    meta: settingsZ17uU3QHWiMeta || {},
    alias: settingsZ17uU3QHWiMeta?.alias || [],
    redirect: settingsZ17uU3QHWiMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: system5ek9qshbMlMeta?.name ?? "system",
    path: system5ek9qshbMlMeta?.path ?? "/system",
    meta: system5ek9qshbMlMeta || {},
    alias: system5ek9qshbMlMeta?.alias || [],
    redirect: system5ek9qshbMlMeta?.redirect || undefined,
    component: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/pages/system.vue").then(m => m.default || m)
  }
]