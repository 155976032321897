import axios from 'axios';
import version from '@/version.json';

export default function send(): Promise<void> {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return axios.post('/api/v1/heartbeat', {
    user_id: user?.id ?? null,
    app_version: version,
    device_id: deviceId(),
    user_agent: navigator.userAgent,
  });
}
