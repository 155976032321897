import validate from "/home/forge/app-test.healthdevelopmentinstitute.com/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  auth: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/auth.ts"),
  guest: () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/guest.ts"),
  "quiz-has-teams": () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/quiz-has-teams.ts"),
  "quiz-lifestyle-access": () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/quiz-lifestyle-access.ts"),
  "quiz-quiz-exists": () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/quiz-quiz-exists.ts"),
  "quiz-team-unfinished": () => import("/home/forge/app-test.healthdevelopmentinstitute.com/middleware/quiz-team-unfinished.ts")
}